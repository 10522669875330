<template>
  <div class="device-repair-wrapper">
    <!-- <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <RepairItem
        v-for="item in list"
        :key="item.id"
        :title="item"
        :item="item"
        :faultLevelEnums="faultLevelEnums"
        @handleRepairStatusClick="handleRepairStatusClick"
      />
    </van-list> -->
    <!-- <div class="check-wrapper"> -->
    <div class="tab-wrapper">
      <van-tabs v-model="tabActive">
        <van-tab title="待维修"></van-tab>
        <van-tab title="已维修"></van-tab>
        <van-tab title="已创建"></van-tab>
      </van-tabs>
    </div>
    <div class="content-wrapper">
      <RepairIng v-if="tabActive === 0" />
      <Repaired v-if="tabActive === 1" />
      <Created v-if="tabActive === 2" />
    </div>
    <!-- </div> -->

    <div class="tab-bar-box" v-show="tabActive === 0">
      <span class="left" @click="handleOpenNumberClick">输入编号</span>
      <span class="right" @click="handleScanClick">扫码</span>
    </div>
    <!--识别结果-->
    <Dialog :show="showCheck" :info="dialogCheckInfo" @handleCloseDialogClick="handleCloseCheckDialogClick" @handleQueryDialogClick="handleQueryCheckDialogClick">
      <div class="info-box">
        <p>该设备存在尚未完结维修工单，是否创建新的故障工单</p>
        <p @click="handleLook">点击查看故障工单>>></p>
      </div>
    </Dialog>

    <Dialog :show="show" :info="dialogInfo" @handleCloseDialogClick="handleCloseDialogClick" @handleQueryDialogClick="handleQueryDialogClick">
      <div class="repair-dialog-info">
        <span class="info-key">设备：</span><span class="info-value">{{
          dialogDetail && dialogDetail.deviceName
        }}</span>
      </div>
      <div class="repair-dialog-info">
        <span class="info-key">编号：</span><span class="info-value">{{
          dialogDetail && dialogDetail.deviceNo
        }}</span>
      </div>
      <div class="repair-dialog-way">
        <van-radio-group v-model="repairWay">
          <van-radio name="1">平台派单</van-radio>
          <van-radio name="2">自行维修</van-radio>
        </van-radio-group>
      </div>
    </Dialog>
    <!--输入编号-->
    <van-popup v-model="showInputNumber" closeable close-icon="close" position="bottom" :style="{ height: '120px', padding: '50px 0' }">
      <div class="number-wrapper">
        <van-field v-model="deviceNo" center clearable label="设备编号" placeholder="请输入设备编号">
          <template #button>
            <van-button size="small" type="info" style="color: white" @click="handleSelectNumberClick">确定</van-button>
          </template>
        </van-field>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
// import RepairItem from './components/RepairItem'

import RepairIng from './components/RepairIng'
import Repaired from './components/Repaired'
import Created from './components/Created'
import Dialog from '@/components/Dialog'
import { Tab, Tabs, List, PullRefresh, Popup, Button, Field, Toast } from 'vant'
import {
  getDeviceRepairList,
  getPreInitiate,
  checkFault,
} from '@/api/deviceRepair'
import { scanCode } from '@/utils/wx'
Vue.use(Tab, Tabs, List, PullRefresh, Popup, Button, Field, Toast)

export default {
  name: 'index',
  components: {
    // RepairItem,
    RepairIng,
    Repaired,
    Created,
    Dialog,
  },
  data() {
    return {
      tabActive: 0,
      show: false,
      showInputNumber: false,
      deviceNo: '',
      deviceUniqueNo: '',
      currentDeviceName: '',
      dialogInfo: {
        title: '设备维修',
        cancerTxt: '取消',
        queryTxt: '创建',
      },
      showCheck: false,
      dialogCheckInfo: {
        title: '温馨提示',
        cancerTxt: '取消',
        queryTxt: '创建',
      },
      list: [],
      loading: false,
      finished: false,
      params: {
        page: 0,
        size: 10,
      },
      faultLevelEnums: {},
      dialogDetail: {},
      repairWay: '1',
      fault: true,
      maintain: true,
      faultCanEidt: true,
      deviceId: '',
    }
  },
  mounted() {
    const { type, deviceName, deviceNo, deviceUniqueNo } = this.$route.query
    if (type === 'sao' && deviceName && deviceNo) {
      this.deviceNo = deviceUniqueNo
      this.deviceUniqueNo = deviceUniqueNo
      this.show = true
      this.$set(this.dialogDetail, 'deviceNo', deviceNo)
      this.$set(this.dialogDetail, 'deviceName', deviceName)
    }
  },
  methods: {
    /**
     * 打开输入编号
     */
    handleOpenNumberClick() {
      this.showInputNumber = true
    },
    /**
     * 确定输入编号
     */
    handleSelectNumberClick() {
      if (!this.deviceNo) {
        Toast('设备编号不能为空！')
        return
      }
      this.getPreInitiate()
    },

    /**
     * 获取维修设备列表
     */
    getDeviceRepairList() {
      if (this.params.page === 0) {
        this.list = []
      }
      getDeviceRepairList(this.params).then((res) => {
        res.content.forEach((item) => {
          switch (item.repairStatus) {
            case -1:
              item.repairStatus = '已撤销'
              break
            case 0:
              item.repairStatus = '待验收'
              break
            case 1:
              item.repairStatus = '维修中'
              break
            case 2:
              item.repairStatus = '验收不通过'
              break
            case 3:
              item.repairStatus = '验收通过'
              break
          }
          this.list.push(item)
        })
        if (this.list.length >= res.totalElements) {
          this.finished = true
        }
        this.params.page = this.params.page + 1
      })
    },
    /**
     * 维修状态
     */
    handleRepairStatusClick(id) {
      this.$router.push({
        path: '/deviceRepair/status',
        query: {
          id: id,
        },
      })
    },
    /**
     * 扫码
     */
    handleScanClick() {
      scanCode((res) => {
        console.log(res)
        getPreInitiate({
          deviceNo: res,
        }).then((res) => {
          console.log(res)
          if (res.retCode === 1) {
            this.showInputNumber = false
            this.dialogDetail = res.details
            this.deviceNo = res.details.deviceUniqueNo
            this.deviceUniqueNo = res.details.deviceUniqueNo

            this.show = true
          } else {
            Toast(res.retMsg)
          }
        })
      })
    },
    /**
     * 获取url参数
     */
    getQueryVariable(url, variable) {
      // const query = window.location.search.substring(1);
      const query = url
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=')
        if (pair[0] == variable) {
          return pair[1]
        }
      }
      return false
    },
    handleLook() {
      this.$router.push({
        path: '/deviceDetail',
        query: {
          id: this.deviceId,
          active: 2,
        },
      })
    },

    handleCloseCheckDialogClick() {
      this.showCheck = false
    },
    handleQueryCheckDialogClick() {
      this.showCheck = false
      if (this.repairWay == '1') {
        this.fault = true
        this.maintain = false
        this.faultCanEidt = true
      } else {
        this.fault = true
        this.maintain = true
        this.faultCanEidt = true
      }
      this.$router.push({
        path: '/deviceRepair/info',
        query: {
          deviceNo: this.deviceUniqueNo,
          fault: this.fault,
          maintain: this.maintain,
          faultCanEidt: this.faultCanEidt,
        },
      })
    },
    /**
     * 关闭弹窗
     */
    handleCloseDialogClick() {
      this.show = false
    },

    /**
     * 确定弹窗
     */
    handleQueryDialogClick() {
      this.checkFault()
    },
    checkFault() {
      checkFault(this.deviceNo).then((res) => {
        if (res.retCode == 1 && res.details.total > 0) {
          this.show = false
          this.showCheck = true
          this.showInputNumber = false
          this.deviceId = res.details.deviceId
        } else {
          this.handleQueryCheckDialogClick()
        }
      })
    },
    getPreInitiate() {
      getPreInitiate({
        deviceNo: this.deviceNo,
      }).then((res) => {
        if (res.retCode === 1) {
          this.showInputNumber = false
          this.dialogDetail = res.details
          this.deviceNo = res.details.deviceUniqueNo
          this.deviceUniqueNo = res.details.deviceUniqueNo
          this.show = true
          this.showCheck = false
        } else {
          Toast(res.retMsg)
        }
      })
    },
    /**
     * 加载
     */
    onLoad() {
      // 异步更新数据
      setTimeout(() => {
        this.getDeviceRepairList()
        this.loading = true
      }, 500)
    },
  },

  beforeRouteEnter(to, from, next) {
    if (from.path == '/') {
      next((vm) => {
        vm.tabActive = 0
      })
    } else {
      next()
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';

.device-repair-wrapper {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  // padding: 0.5rem 0.5rem 2.5rem;
  background-color: #f1f1f1;

  .tab-bar-box {
    @include wh(100%, 2.3rem);
    background-color: #f1f1f1;
    position: fixed;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    border-top: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      display: block;
      height: 100%;
      font-size: 0.7rem;
      @include CC;
    }

    span.left {
      width: 7rem;
      color: #666666;
    }

    span.right {
      flex: 1;
      color: white;
      background: linear-gradient(
        90deg,
        rgba(79, 147, 255, 1) 0%,
        rgba(58, 187, 253, 1) 100%
      );
    }
  }

  .number-wrapper {
    width: 95%;
    margin: 0 auto;
    border: 1px solid #e2e2e2;
    border-radius: 0.3rem;

    /deep/.van-button {
      span {
        color: white;
      }
    }
  }

  .block {
    .repair-dialog-info {
      margin: 0.2rem 0;

      .info-key {
        color: #999999;
        font-size: 0.7rem;
      }

      .info-value {
        color: #333;
        font-size: 0.7rem;
      }
    }

    .repair-dialog-way {
      /deep/ .van-radio-group {
        margin-top: 1rem;

        .van-radio {
          text-align: center;
          margin: 0.3rem auto;

          .van-radio__icon {
            font-size: 0.7rem;
            color: #333;
            font-weight: bold;
          }

          .van-radio__label {
            font-size: 0.7rem;
            color: #333;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.info-box {
  margin: 1rem 0;
  p {
    font-size: 0.7rem;
    color: #999999;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin: 0.5rem 0;
  }
  p:nth-child(2) {
    color: #409eff;
  }
}
</style>
