<template>
  <div class="executed-list-wrapper">
    <!-- <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
     
      <div class="executed-item-box">
        <div class="bottom">
          <ul>
            <li v-for="(item, index) in list" :key="index" @click="handleRepairStatusClick(item)">
              <div class="left">
                <i class="iconfont icondianjianguanli"></i>
                <span>{{ item.deviceName }}</span>
              </div>
              <div class="right">
                <span>{{ item.faultStatus }}</span>
                <i class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </van-list> -->

    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="executed-item-box" v-for="(item, index) in list" :key="index" @click="handleRepairStatusClick(item)">
        <div class="item-box-info">
          <div class="info-left">
            <i class="iconfont icondianjianguanli"></i>
          </div>
          <div class="info-center">
            <span class="title">{{item.deviceName}}</span>
            <div class="detail">
              <span>设备编号：{{item.deviceNo}}</span>
              <span>发生时间：{{item.commitTime}}</span>
              <span class="ellipsis">故障内容：{{item.faultReason}}</span>
              <span class="ellipsis">接单人：{{item.receiveUserName}}</span>

            </div>
          </div>
          <div class="info-right">
            <!-- <span>{{ item.faultStatus }}</span>
            <i class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing"></i> -->
            <span :style="{'color':filterColor(item.faultStatus)  }">{{item.faultStatusName}}</span>
            <i class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing"></i>
          </div>
        </div>

      </div>
    </van-list>
    <!-- <Dialog
      :show="show"
      :info="dialogInfo"
      @handleCloseDialogClick="handleCloseDialogClick"
      @handleQueryDialogClick="handleQueryDialogClick"
    >
      <div class="repair-dialog-info">
        <span class="info-key">设备：</span><span class="info-value">设备</span>
      </div>
      <div class="repair-dialog-info">
        <span class="info-key">设备：</span><span class="info-value">设备</span>
      </div>
      <div class="repair-dialog-way">
        <van-radio-group v-model="repairWay">
          <van-radio name="1">平台派单</van-radio>
          <van-radio name="2">自行维修</van-radio>
        </van-radio-group>
      </div>
    </Dialog> -->
    <!-- <Dialog1
      :show="show1"
      :info="dialogInfo1"
      @handleQueryDialogClick="handleQueryDialogClick1"
    /> -->
  </div>
</template>

<script>
import Vue from 'vue'
// import Dialog from '@/components/Dialog'
import { List, RadioGroup, Radio } from 'vant'
import { getDeviceRepairFinishList } from '@/api/deviceRepair'
Vue.use(List, RadioGroup, Radio)
export default {
  name: 'ExecutedList',
  components: {
    // Dialog,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      show: false,
      dialogInfo: {
        title: '维修对象',
        cancerTxt: '取消',
        queryTxt: '创建',
      },
      params: {
        page: 0,
        size: 10,
      },
      currentTaskId: 0,
      repairWay: '1',
    }
  },
  methods: {
    /**
     * 列表
     */
    getDeviceRepairFinishList() {
      getDeviceRepairFinishList(this.params).then((res) => {
        if (this.params.page === 0) {
          this.list = []
        }
        res.content.forEach((item) => {
          this.list.push(item)
        })
        this.loading = false
        if (
          this.list.length >= res.totalElements ||
          res.content.length == '0'
        ) {
          this.finished = true
          this.loading = true
        } else {
          this.params.page = this.params.page + 1
        }

        // const content = res.content
        // let arr = []
        // content.forEach((item) => {
        //   arr.push(item)
        // })
        // this.list = arr
        // if (this.list.length >= res.totalElements) {
        //   this.finished = true
        // }
        // this.params.page = this.params.page + 1
      })
    },
    // handleCloseDialogClick() {
    //   this.show = false
    // },
    /**
     * 维修状态
     */
    handleRepairStatusClick(item) {
      this.$router.push({
        path: '/deviceRepair/status',
        query: {
          id: item.id,
          show: false,
        },
      })
    },
    // handleQueryDialogClick() {
    //   this.show = false
    //   this.$router.push({
    //     path: '/deviceRepair/info',
    //     query: {
    //       deviceNo: this.number,
    //     },
    //   })
    //   this.show = false
    // },
    onLoad() {
      // 异步更新数据
      setTimeout(() => {
        this.getDeviceRepairFinishList()
        this.loading = true
      }, 500)
    },
    filterColor(val) {
      var statusList = {
        0: '#E6A23C',
        1: '#4A8CFF',
        2: '#4A8CFF',
        3: '#67C23A',
        4: '#67C23A',
        5: '#F56C6C',
        '-1': '#CCCCCC',
      }
      return statusList[val]
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../style/mixin';
.executed-list-wrapper {
  box-sizing: border-box;
  padding: 1rem;
  background-color: #f1f1f1;
  .executed-item-box {
    margin-bottom: 1rem;
    background-color: white;
    border-radius: 0.3rem;
    .item-box-title {
      padding: 0.3rem 0.5rem;
      span {
        color: #999999;
        font-size: 0.7rem;
        font-weight: bold;
      }
      border-bottom: 1px solid #ebedf0;
    }
    .item-box-info {
      padding: 0.3rem 0rem;
      display: flex;
      flex-direction: row;
      align-items: top;
      .info-left {
        margin: 0 0.55rem;
        i {
          color: #409eff;
          font-size: 1rem;
        }
      }
      .info-center {
        width: 0;
        flex: 1;
        text-align: justify;
        text-justify: newspaper;
        word-break: break-all;
        .title {
          display: block;
          color: #333333;
          font-size: 0.68rem;
          font-weight: bold;
          margin: 0.4rem 0;
        }
        .detail {
          margin: 0.2rem 0;
          span {
            color: #666;
            font-size: 0.5rem;
            display: block;
            margin: 0.3rem 0;
            font-weight: bold;
          }
        }
      }
      .info-right {
        padding: 0 0.5rem;
        span {
          font-size: 0.5rem;
          font-weight: bold;
        }
      }
    }
  }
  // .executed-item-box {
  //   margin-bottom: 1rem;
  //   background-color: white;
  //   border-radius: 0.3rem;
  //   .top {
  //     @include wh(100%, 2.2rem);
  //     border-bottom: 1px solid #f1f1f1;
  //     box-sizing: border-box;
  //     padding-left: 0.6rem;
  //     line-height: 2.2rem;
  //     font-size: 0.85rem;
  //     color: #999999;
  //   }
  //   .bottom {
  //     ul {
  //       li {
  //         @include wh(100%, 2.5rem);
  //         border-bottom: 1px solid #f1f1f1;
  //         display: flex;
  //         justify-content: space-between;
  //         align-items: center;
  //         box-sizing: border-box;
  //         padding: 0 0.6rem;
  //         .left {
  //           display: flex;
  //           align-items: center;
  //           i {
  //             color: #4a8cff;
  //             font-size: 1rem;
  //             margin-right: 0.5rem;
  //             margin-top: -0.2rem;
  //           }
  //           span {
  //             font-size: 0.7rem;
  //             color: #333333;
  //           }
  //         }
  //         .right {
  //           display: flex;
  //           align-items: center;
  //           i {
  //             font-size: 0.9rem;
  //             margin-left: 0.1rem;
  //             margin-top: -0.3rem;
  //           }
  //           span {
  //             font-size: 0.7rem;
  //             color: #4a8cff;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  .block {
    .repair-dialog-info {
      margin: 0.2rem 0;
      .info-key {
        color: #999999;
        font-size: 0.7rem;
      }
      .info-value {
        color: #333;
        font-size: 0.7rem;
      }
    }
    .repair-dialog-way {
      /deep/ .van-radio-group {
        margin-top: 1rem;
        .van-radio {
          text-align: center;
          margin: 0.3rem auto;
          .van-radio__icon {
            font-size: 0.7rem;
            color: #333;
            font-weight: bold;
          }
          .van-radio__label {
            font-size: 0.7rem;
            color: #333;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
